<template>
  <BasePage>
    <Container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row v-if="item && !loading">
        <v-col cols="12" md="8">
          <div class="white pa-5">
            <h4 class="text-h4 mb-5 primary--text">
              {{ item[lang + 'title'] }}
            </h4>
            <p class="text-body-2 red--text text--darken-2 font-weight-light">
              {{ $t('myFatoorahNote') }}
            </p>
          </div>
          <template v-if="paymentMethods && !loading">
            <div class="mt-14">
              <div class="text-h4">{{ $t('selectPaymentMethod') }}</div>
              <v-radio-group v-model="selectedMethod">
                <div class="grey lighten-4 pa-4">
                  <div class="radio-wrapper">
                    <v-radio
                      v-for="method in paymentMethods"
                      :key="method.PaymentMethodId"
                      :label="method.PaymentMethodAr"
                      :value="method.PaymentMethodId"
                      class="mb-3 d-flex align-center radio-select-option pa-2"
                      :class="
                        selectedMethod === method.PaymentMethodId &&
                          'payment-method-selected'
                      "
                    >
                      <template v-slot:label>
                        <!-- <div class="d-flex justify-space-between"> -->
                        <v-row>
                          <v-col
                            cols="12"
                            class="d-flex flex-row align-center"
                            :class="computedRadioOption"
                          >
                            <div style="width: 12%">
                              <v-img
                                width="100%"
                                :src="method.ImageUrl"
                                contain
                              />
                            </div>
                            <span class="body-1 px-2">{{
                              method[
                                lang == 'ar'
                                  ? 'PaymentMethodAr'
                                  : 'PaymentMethodEn'
                              ]
                            }}</span>
                            <v-btn
                              color="warning"
                              elevation="0"
                              class="mx-2"
                              v-if="selectedMethod === method.PaymentMethodId"
                              @click="executePayment(method.PaymentMethodId)"
                              >{{ $t('pressHere') }}</v-btn
                            >

                            <!-- </div> -->
                          </v-col>
                          <!-- <v-col cols="11">
                          </v-col> -->
                        </v-row>
                        <!-- <v-row>
                          <v-col cols="1">
                            <v-img
                              width="75%"
                              :src="method.ImageUrl"
                              contain
                            />
                          </v-col>
                          <v-col cols="11">
                            <span class="body-1">{{
                              method[
                                lang == 'ar'
                                  ? 'PaymentMethodAr'
                                  : 'PaymentMethodEn'
                              ]
                            }}</span>
                          </v-col>
                        </v-row> -->
                        <!-- <v-btn
                          color="warning"
                          elevation="0"
                          class="mx-2"
                          v-if="selectedMethod === method.PaymentMethodId"
                          @click="executePayment(method.PaymentMethodId)"
                          >{{ $t('pressHere') }}</v-btn
                        > -->
                        <!-- </div> -->
                      </template>
                    </v-radio>
                  </div>
                </div>
              </v-radio-group>
              <!-- </template> -->
            </div>
          </template>
        </v-col>
        <v-col cols="12" md="4">
          <div class="white pa-5">
            <div>
              <div class="d-flex flex-row mb-6">
                <h4
                  class="text-h4"
                  :class="newPrice ? 'error--text' : 'primary--text'"
                  :style="{
                    textDecoration: newPrice ? 'line-through' : 'none',
                  }"
                >
                  {{ item.currency_id.title + ' ' + item.after_discount }}
                </h4>

                <h4 class="text-h4 mx-2 success--text" v-if="newPrice">
                  {{
                    parseInt(newPrice) !== 0
                      ? item.currency_id.title + newPrice
                      : $t('free').toUpperCase()
                  }}
                </h4>
              </div>

              <div class="d-flex align-center justify-center" dir="ltr">
                <v-text-field
                  outlined
                  dense
                  :label="$t('enterCoupon')"
                  class="rounded-r-0 coupon-input"
                  style="width: 50%;height: 40px;"
                  :error-messages="invalidCoupon ? $t('invalidCoupon') : ''"
                  v-model="coupon"
                  :error="invalidCoupon"
                />
                <div class="rounded-l-0 coupon-btn-style">
                  {{ $t('coupon') }}
                </div>
              </div>

              <v-btn
                class="mt-6 rounded-0"
                color="secondary"
                elevation="0"
                style="width:100%;height: 50px"
                :disabled="
                  isPaymentBtnDisabled ||
                    (paymentMethods && paymentMethods.length > 0)
                "
                @click="checkout"
                outlined
                >{{ $t('continueToPay') }}</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  props: ['id'],
  components: {
    // Dialog: () => import('@/components/Dialog'),
  },
  data() {
    return {
      loading: false,
      loadingPaymentUrl: false,
      item: null,
      paymentMethods: null,
      selectedMethod: '',
      paymentUrl: null,
      InvoiceId: null,
      coupon: '',
      newPrice: null,
      couponInfo: null,
      enrollmentId: null,
      is_super: false,
      lang: this.$root.lang == 'en' ? 'en' : '',
      domain: process.env.VUE_APP_DOMAIN,
      invalidCoupon: false,
      freeSale: false,
      isPaymentBtnDisabled: false,
    };
  },
  methods: {
    async getItemInfo() {
      try {
        this.loading = true;
        const { data } = await ApiService.get(
          `items/courses/${this.id}?fields=*,currency_id.id,currency_id.title`
        );

        this.item = data.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async checkout() {
      this.newPrice = null;
      await this.initCouponProcess();

      if (this.invalidCoupon === false) {
        // console.log('init payment');
        await this.initPayment();
      } else {
        console.log('coupon not valid');
      }
    },

    getPaymentStatus() {
      if (this.is_super) return 'full_discount';
      else if (this.freeSale) return 'free';
      else return 'pending';
    },

    async initPayment() {
      try {
        this.loading = true;
        // console.log(
        //   'init',
        //   Number(
        //     (this.newPrice || this.item.after_discount) /
        //       this.settingsGetter?.currency_value
        //   ).toFixed(3)
        // );

        // post to enrollements table [to store all user's actions during all payemnt process]
        const enrollmentPayload = {
          student_id: this.userGetter.currentUser.id,
          date: new Date().toISOString().slice(0, 10),
          course_id: this.id,
          coupon_id: this.couponInfo?.id,
          price: this.newPrice || this.item.after_discount,
          currency_id: this.item.currency_id.id,
          coupon_is_super: this.is_super,
        };

        const newEnrollment = await ApiService.post(
          'items/enrollment',
          enrollmentPayload
        );

        this.enrollmentId = newEnrollment.data.data.id;

        // if coupon is super => redirect to payment success
        if (this.is_super || this.freeSale) {
          console.log('redirecting....');
          const navTo = `${
            this.domain
          }/#/payment-result?payment_status=${this.getPaymentStatus()}&enrollmentId=${
            this.enrollmentId
          }&typeId=${this.item.type_id.id}`;
          // const navTo = `${this.domain}/#/payment-result?payment_status=full_discount&enrollmentId=${this.enrollmentId}&typeId=${this.item.type_id.id}`;
          this.loading = false;
          // console.log('nav to ', navTo);
          return window.location.assign(navTo);
        }

        // console.log('test', test.search);

        let params = new URLSearchParams();
        params.append(
          'InvoiceAmount',
          this.newPrice || this.item.after_discount
        );

        // print URLSearchParams content in the console
        // for (var pair of params.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const { data } = await axios.post(
          `${this.domain}/InitiatePayment.php`,
          params,
          config
        );
        // console.log(data);
        this.paymentMethods = data.Data.PaymentMethods;
        // console.log(data.Data);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async executePayment(paymentMethodId) {
      try {
        this.loading = true;

        const params = new URLSearchParams();
        params.append('PaymentMethodId', paymentMethodId);
        params.append(
          'name',
          this.userGetter.currentUser.first_name +
            this.userGetter.currentUser.last_name
        );
        params.append('MobileCountryCode', '965');
        params.append('CustomerMobile', '1111222333');
        params.append('CustomerEmail', this.userGetter.currentUser.email);
        params.append(
          'InvoiceValue',
          Number(
            (this.newPrice || this.item.after_discount) /
              this.settingsGetter?.currency_value
          ).toFixed(3)
          // this.newPrice || this.item.after_discount
        );
        params.append('DisplayCurrencyIso', 'KWD');
        params.append(
          'CallBackUrl',
          `${this.domain}/#/payment-result?payment_status=success&enrollmentId=${this.enrollmentId}&typeId=${this.item.type_id.id}`
        );
        params.append(
          'ErrorUrl',
          `${this.domain}/#/payment-result?payment_status=faild&enrollmentId=${this.enrollmentId}`
        );
        params.append('ItemName', this.item.title);
        params.append('Quantity', 1);
        params.append(
          'UnitPrice',
          Number(
            (this.newPrice || this.item.after_discount) /
              this.settingsGetter?.currency_value
          ).toFixed(3)
        );
        // ${this.domain}/#/?userID=5&courseId=10&price=20&currencyId=1&paymentId=060667345654094062&Id=060667345654094062

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };
        // console.log('params 1', params);
        const { data } = await axios.post(
          `${this.domain}/executePayment.php`,
          params,
          config
        );
        console.log(data);
        console.log('params 2', params);

        if (!data.IsSuccess) {
          this.loading = false;
          return console.log('error occured 1');
        }

        // patch enrollment table with myfatoorah invoiceId
        await ApiService.update('items/enrollment', this.enrollmentId, {
          invoice_id: data.Data.InvoiceId,
        });

        window.location.assign(data.Data.PaymentURL, '_blank');
        this.loading = false;
      } catch (error) {
        console.log('error occured 2');
        console.log(error);
        this.loading = false;
      }
    },

    async getCouponInfo() {
      const { data } = await ApiService.get(
        `items/coupons?fields=id,code,courseid,category_id,type_id,tag_id,status,user_max_num,enrollments.student_id,enrollments.payment_status,start_date,exp_date,value,is_persentage,is_super&filter[code]=${this.coupon}&filter[status][in]=published,free`
      );

      return data.data[0];
    },

    validateCoupon(couponInfo) {
      if (
        !couponInfo ||
        couponInfo.enrollments.find(
          (item) =>
            item.student_id == this.userGetter.currentUser.id &&
            this.userGetter.successStatuses.includes(item.payment_status)
          // (item.payment_status == 'success' ||
          //   item.payment_status == 'free' ||
          //   item.payment_status == 'full_discount')
        ) ||
        couponInfo?.user_max_num <= couponInfo?.enrollments?.length ||
        // couponInfo.user_max_num == 0 ||
        couponInfo.start_date > new Date().toISOString().slice(0, 10) ||
        couponInfo.exp_date < new Date().toISOString().slice(0, 10)
      ) {
        console.log(
          'you have already used this coupon before / invalid coupon #1'
        );
        this.invalidCoupon = true;
        this.loading = false;
        // return;
      }
    },

    isCouponCoursIdMatchesLocalId(couponInfo) {
      //couponInfo.courseid &&
      if (+couponInfo.courseid?.id !== +this.id) {
        this.invalidCoupon = true;
        this.loading = false;
        // return;
      }
    },

    async checkCouponPermissions(couponInfo) {
      let isValid = false;
      let courseInfo = await ApiService.get(
        `items/courses/${this.id}?fields=type_id,category_id,tag_id`
      );
      courseInfo = courseInfo.data.data;
      // console.log('coursen info', courseInfo);

      if (
        (couponInfo.category_id?.id &&
          courseInfo.category_id == couponInfo.category_id?.id) ||
        (couponInfo.type_id?.id &&
          courseInfo.type_id == couponInfo.type_id?.id) ||
        (couponInfo.tag_id?.id && courseInfo.tag_id == couponInfo.tag_id?.id)
      ) {
        isValid = true;
        // console.log('helal olsun 2');
      } else {
        this.invalidCoupon = true;
        // console.log('invalid coupon');
      }

      return isValid;
    },

    applyCoupon(isValid, couponInfo) {
      if (isValid && (couponInfo.is_super || couponInfo.status == 'free')) {
        this.couponInfo = couponInfo;
        this.newPrice = '00.00';
        couponInfo.is_super && (this.is_super = true);
        couponInfo.status == 'free' && (this.freeSale = true);
      } else if (isValid) {
        this.couponInfo = couponInfo;

        if (couponInfo.is_persentage) {
          this.newPrice =
            this.item.after_discount -
            (couponInfo.value * this.item.after_discount) / 100;
        } else {
          this.newPrice = +this.item.after_discount - +couponInfo.value;
        }
      } else {
        this.invalidCoupon = true;
        // isPaymentBtnDisabled = false;
      }
    },

    async initCouponProcess() {
      if (!this.coupon.length) return;
      try {
        let isValid = false;
        this.invalidCoupon = false;
        this.newPrice = null;
        this.loading = true;

        const couponInfo = await this.getCouponInfo();

        // check coupon validatity
        this.validateCoupon(couponInfo);
        if (this.invalidCoupon) {
          return;
        }

        // check if couponInfo is null, it should NOT continue
        console.log('continue....');

        /**
         * i think the following tow checks have a bug because the coupon can be applied over a course, category, or a tag,
         * but according to our check, if the coupon is not applied to the course it will break and wont chck the others.
         * 4talents dont use these conditions thats why we will keep it as it's.
         */
        this.isCouponCoursIdMatchesLocalId(couponInfo);
        if (this.invalidCoupon) {
          return;
        }

        if (couponInfo.courseid.id == this.id) {
          isValid = true;
        } else {
          isValid = await this.checkCouponPermissions(couponInfo);
        }

        this.applyCoupon(isValid, couponInfo);

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(['cartGetter', 'userGetter', 'settingsGetter']),
    computedRadioOption() {
      return this.$vuetify.breakpoint.smAndDown
        ? 'justify-start'
        : 'justify-end';
    },
  },

  watch: {
    newPrice(newVal) {
      this.isPaymentBtnDisabled = newVal == null ? false : true;
    },
  },

  mounted() {
    this.getItemInfo();
  },
};
</script>

<style scoped>
.payment-method-selected {
  background: #eee;
}
.radio-select-option:hover {
  padding: 4px;
  /* background: var(--v-primary-base); */
  background: #eee;
  transition: all 0.4s ease-in-out;
}

.coupon-btn-style {
  width: 20%;
  height: 40px;
  background-color: var(--v-primary-base);
  color: white;
  text-align: center;
  line-height: 40px;
}

.invalid-coupon {
  font-weight: bold;
}

.radio-wrapper {
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .radio-wrapper {
    width: 100%;
  }
}

/* Styles for medium and larger screens */
/* @media (min-width: 601px) {
  .radio-wrapper {
    width: 100%;
    margin: 0 auto;
  }
} */

/* .v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
} */
</style>
